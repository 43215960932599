var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "t-toast",
      class: { "t-toast--top-center": _vm.recentPosition == "top-center" },
      attrs: { id: "t-toast" },
    },
    [
      _c(
        "transition",
        {
          staticClass: "t-toast__list",
          attrs: { name: "toast-list", tag: "div" },
        },
        [
          !_vm.$isEmpty(_vm.toast)
            ? _c(
                "div",
                {
                  staticClass: "t-toast__list-item",
                  class: _vm.type(_vm.toast.type),
                },
                [
                  _c(
                    "div",
                    { staticClass: "t-toast__content" },
                    [
                      _c("div", { staticClass: "t-toast__icon" }, [
                        _vm.toast.type == "success"
                          ? _c("i", {
                              staticClass: "fas fa-check-circle fa-fw",
                            })
                          : _vm.toast.type == "error"
                          ? _c("i", {
                              staticClass: "fas fa-exclamation-circle fa-fw",
                            })
                          : _c("i", {
                              staticClass: "fas fa-info-circle fa-fw",
                            }),
                      ]),
                      _vm._v(" "),
                      _vm.toast.message
                        ? _c("div", { staticClass: "t-toast__content" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.toast.message) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.toast.dismissible
                        ? _c(
                            "t-button",
                            {
                              staticClass: "t-toast__close",
                              attrs: { variant: "action" },
                              on: {
                                click: function ($event) {
                                  return _vm.$remove(_vm.toast.id)
                                },
                              },
                            },
                            [_c("i", { staticClass: "fas fa-times fa-fw" })]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
  button: {
    preview: 'Preview',
    preview_and_publish: 'Preview and Publish',
    proceed: 'Proceed',
    save_n_publish: 'Save & Publish',
    edit: 'Edit',
    discard: 'Discard Draft',
    delete_image: 'Delete Image',
    clone_image: 'Clone Image',
    image_fill: 'Use as Image Fill'
  },
  clip_audio: {
    title: 'Clip audio',
    no_audio: 'No audio added for this clip',
    duration: 'Audio Duration',
    tts: {
      title: 'Generate speech using AI',
      placeholder: `Start typing to use this space as the script for AI Text-to-speech. Use 'Comma' for short pauses and 'Line break' for long pauses.`
    },
    manual: {
      title: 'Record using your voice',
      placeholder:
        'Start typing to use this space as notes for yourself while recording your voice with/without your face.'
    },
    upload: {
      title: 'Upload file(.mp3)',
      placeholder:
        'Upload your .mp3 file. Maximum uploading limit is 5MB. Contact support if you wish to increase the limit.'
    },
    voice_modal: {
      title: 'Voice Settings',
      description:
        'Choose your preferred language and accent for AI text-to-speech conversion.'
    },
    voice_settings: 'Change Voice'
  },
  uploaded_video: {
    title: 'Uploaded Video',
    in_progress: 'Your video is getting processed. Please wait for few seconds'
  },
  narration: {
    offset_start: 'Narration Start Time (in secs)',
    narration_duration: 'Duration of the block (in secs)',
    errors: {
      empty_file:
        'Recorded audio was not proper. Please record the audio again',
      manual_default:
        'There were some problems with the audio uploaded. Please retry.',
      video_manual_default:
        'There were some problems with the video uploaded. Please retry.',
      default: 'Sorry something went wrong, please retry again'
    },
    on_board: {
      p1: 'Screen capture completed',
      p1_ppt: 'File upload completed',
      p2: "Let's add audio"
    },
    type: {
      label: 'What do you prefer for audio?',
      voices: 'What voice do your prefer for narration?',
      back: 'Change Template',
      tts: 'Use text to speech converter for voice over',
      manual: 'Let me record & add my own voice',
      choose: 'Select Audio Type',
      tts_title: 'Text to Speech',
      manual_title: 'Record Narration',
      upload: 'Upload Audio',
      switch: 'switch'
    },
    manual_support: 'To add narration with your own voice.',
    tts: {
      intro_title: 'Add Introductory Audio - Text to Speech',
      title: 'Audio for Selected Block - Text to Speech',
      placeholder:
        'Enter the text here that would be converted to speech (Use voice settings to change the language, voice style, pronounciations and more...)'
    },
    language: {
      title: 'Select the language for the narration',
      placeholder: 'Select the language for the narration',
      free_limit: {
        1: 'In your current plan, you can add voice overs in only one language. Please change the default language in ',
        2: 'workspace settings page'
      }
    },
    tts_tips: {
      head: 'Did you know you can use,',
      1: 'Comma for short pause',
      2: 'Line break for long pause',
      3: 'Quotes for emphasize'
    },
    voice_over: {
      title: 'Voice Recording',
      speaker_notes: 'Speaker Notes',
      speaker_notes_placeholder:
        'I am a sample note. I will be helpful while recording.',
      recording: {
        start: 'Start Recording',
        starting: 'Starting Recording',
        stop: 'Stop Recording',
        cancel: 'Cancel Recording',
        uploading: 'Uploading',
        waiting_to_upload: 'Waiting to Upload',
        delete: 'Move to Trash',
        manual_upload: 'Upload Audio',
        rerecord: 'Re-record',
        select_audio_input: 'Select Audio Input'
      },
      save_notes: 'Save Speaker Notes',
      recording_placeholder: 'Click on Start Recording',
      devices: {
        label: 'Available audio devices'
      },
      track: 'Audio track',
      video_track: 'Video track'
    },
    video: {
      title: 'Video Recording'
    },
    intro_context: 'Introductory slide to set context to the users',
    intro_placeholder: 'Enter a narration',
    label: {
      intro: 'Introduction',
      step: {
        click: 'You clicked on this frame',
        highlight: 'You highlighted this frame',
        input: 'Input event',
        default: 'Highlighted frame',
        custom: 'This is a custom frame'
      },
      outro: 'Thankyou note'
    },
    delete_step: {
      label: 'Unwanted step? Ignore',
      description: 'Both highlighting and audio will be ignored'
    },
    voices: {
      matthew: 'Matthew, Male (English, US)',
      joanna: 'Joanna, Female (English, US)',
      eric: 'Eric, Male (English, US)',
      amy: 'Amy, Female (English, British)',
      emma: 'Emma, Female (English, British)',
      ashley: 'Ashley, Female (English, US)',
      joey: 'Joey, Male (English, US)',
      brian: 'Brian, Male (English, British)',
      arthur: 'Arthur, Male (English, British)',
      lucia: 'Lucia, Female(Spanish, European)',
      enrique: 'Enrique, Male(Spanish, European)',
      lupe: 'Lupe, Female(Spanish, US)',
      lea: 'Lea, Female (French, FR)',
      remi: 'Remi, Male (French, FR)',
      mathieu: 'Mathieu, Male (French, FR)',
      denise: 'Denise, Female (French, FR)',
      henri: 'Henri, Male (French, FR)',
      camila: 'Camila, Female(Portuguese, Brazilian)',
      ricardo: 'Ricardo, Male(Portuguese, Brazilian)',
      cristiano: 'Cristiano, Male(Portuguese, European)',
      aditi: 'Aditi, Female(English, Indian)',
      aditi_hindi: 'Aditi, Female(Hindi, Indian)',
      anjali: 'Anjali, Female(Hindi, Indian)',
      ankita: 'Ankita, Female(Hindi, Indian)',
      raj: 'Raj, Male(Hindi, Indian)',
      sanjay: 'Sanjay, Male(Hindi, Indian)',
      madee: 'Madee, Female(Thai, Thailand)',
      pallavi: 'Pallavi, Female(Tamil, Indian)',
      vetri: 'Vetri, Male(Tamil, Indian)',
      mathu: 'Mathu, Female(English, Indian)',
      advaith: 'Advaith, Male(English, Indian)',
      adithya: 'Adithya, Male(English, Indian)',
      priya: 'Priya, Female(English, Indian)',
      malavika: 'Malavika, Female(Kannada, Indian)',
      ragu: 'Ragu, Male(Kannada, Indian)',
      shraddha: 'Shraddha, Female(Marathi, Indian)',
      sunil: 'Sunil, Male(Marathi, Indian)',
      udhay: 'Udhay, Male(Telugu, Indian)',
      shruthi: 'Shruthi, Female(Telugu, Indian)',
      celine: 'Celine, Female(Malayalam, Indian)',
      abijith: 'Abijith, Male(Malayalam, Indian)',
      klarissa: 'Klarissa, Female(German, German)',
      vicki: 'Vicki, Female(German, German)',
      daniel: 'Daniel, Male(German, German)',
      hannah: 'Hannah, Female(German, German)',
      yasmin: 'Yasmin, Female(Arabic, Middle Eastern)',
      omar: 'Omar, Male(Arabic, Middle Eastern)',
      gwen: 'Gwen, Female (English, US)',
      peter: 'Peter, Male (English, US)',
      michelle: 'Michelle, Female (English, US)',
      aakash: 'Aakash, Male (Bengali, Indian)',
      aadya: 'Aadya, Female (Bengali, Indian)',
      minh: 'Minh, Male (Vietnamese, VN)',
      linh: 'Linh, Female (Vietnamese, VN)',
      adam: 'Adam, Male (Malay, MS)',
      farah: 'Farah, Female (Malay, MS)',
      jenny: 'Jenny, Female (English, US)',
      davis: 'Davis, Male (English, US)',
      aria: 'Aria, Female (English, US)',
      guy: 'Guy, Male (English, US)',
      sara: 'Sara, Female (English, US)',
      tony: 'Tony, Male (English, US)',
      nancy: 'Nancy, Female (English, US)',
      jason: 'Jason, Male (English, US)',
      brandon: 'Brandon, Male (English, US)',
      jacob: 'Jacob, Male (English, US)',
      beth: 'Beth, Female (English, US)',
      ella: 'Ella, Female (English, US)',
      jane: 'Jane, Female (English, US)',
      james: 'James, Male (English, US)',
      donna: 'Donna, Female (English, US)',
      kevin: 'Kevin, Male (English, US)',
      laura: 'Laura, Female (English, US)',
      sandra: 'Sandra, Female (English, US)',
      george: 'George, Male (English, British)',
      ember: 'Ember, Female (English, British)',
      wesley: 'Wesley, Male (English, British)',
      heather: 'Heather, Female (English, British)',
      maizie: 'Maizie, Female (English, British)',
      kimberly: 'Kimberly, Female (English, US)',
      kendra: 'Kendra, Female (English, US)',
      kajal: 'Kajal, Female (English, Indian)',
      salli: 'Salli, Female (English, US)',
      stephen: 'Stephen, Male (English, US)',
      zhiyu: 'Zhiyu, Female (Chinese, Mandarin, Simplified)',
      xiaoxiao: 'XiaoXiao, Female (Chinese, Mandarin, Simplified)',
      yunze: 'Yunze, Male (Chinese, Mandarin, Simplified)',
      mia: 'Mia, Female (Spanish, Mexican)',
      andres: 'Andres, Male (Spanish, Mexican)',
      morten: 'Morten, Male (Danish, Denmark)',
      anne: 'Anne, Female (Danish, Denmark)',
      ola: 'Ola, Female (Polish, poland)',
      jan: 'Jan, Male (Polish, poland)',
      elin: 'Elin, Female (Swedish, Sweden)',
      johan: 'Johan, Male (Swedish, Sweden)',
      elsa: 'Elsa, Female (Italian, Italy)',
      isabella: 'Isabella, Female (Italian, Italy)',
      diego: 'Deigo, Male (Italian, Italy)',
      fenna: 'Fenna, Female (Dutch, Netherlands)',
      maarten: 'Maarten, Male (Dutch, Netherlands)',
      vlasta: 'Vlasta, Female (Czech, Czech Republic)',
      antonin: 'Antonin, Male (Czech, Czech Republic)',
      sunhi: 'SunHi, Female (Korean, Korea)',
      injoon: 'InJoon, Male (Korean, Korea)',
      gookmin: 'GookMin, Male, (Korean, Korea)',
      jimin: 'JiMin, Female, (Korean, Korea)',
      lee: 'Lee, Male (English, AU)',
      phoebe: 'Phoebe, Female (English, AU)',
      miri: 'Miri, Female (English, AU)',
      isla: 'Isla, Female (English, AU)',
      oliver: 'Oliver, Male (English, AU)',
      hazel: 'Hazel, Female (English, AU)',
      levi: 'Levi, Male (English, AU)',
      sophie: 'Sophie, Female (English, AU)',
      liam: 'Liam, Male (English, AU)',
      ellie: 'Ellie, Female (English, AU)',
      oscar: 'Oscar, Male (English, AU)',
      olivia: 'Olivia, Female (English, AU)',
      russell: 'Russell, Male (English, AU)',
      nicole: 'Nicole, Female (English, AU)',
      natasha: 'Natasha, Female (English, AU)',
      kim: 'Kim, Female (English, AU)',
      ken: 'Ken, Male (English, AU)',
      neil: 'Neil, Male (English, AU)',
      miles: 'Miles, Male (English, AU)',
      ayla: 'Ayla, Female (English, AU)',
      zara: 'Zara, Female (English, AU)',
      takumi: 'Takumi, Male (Japanese, JP)',
      kazuha: 'Kazuha, Female (Japanese, JP)',
      tomoko: 'Tomoko, Female (Japanese, JP)',
      keita: 'Keita, Male (Japanese, JP)',
      naoki: 'Naoki, Male (Japanese, JP)',
      shiori: 'Shiori, Female (Japanese, JP)',
      hila: 'Hila, Female (Hebrew, HE)',
      avri: 'Avri, Male (Hebrew, HE)',
      shira: 'Shira, Female (Hebrew, HE)',
      lavi: 'Lavi, Male (Hebrew, HE)',
      romi: 'Romi, Female (Hebrew, HE)',
      ari: 'Ari, Male (Hebrew, HE)',
      chris: 'Chris, Male (English, US)',
      antoni: 'Antoni, Male (English, US)',
      bill: 'Bill, Male (English, US)',
      dorothy: 'Dorothy, Female (English, US)',
      serena: 'Serena, Female (English, US)',
      matilda: 'Matilda, Female (English, US)',
      liam_11: 'Liam, Male (English, US)',
      gregory: 'Gregory, Male (English, US)',
      patrick: 'Patrick, Male (English, US)',
      danielle: 'Danielle, Female (English, US)',
      ruth: 'Ruth, Female (English, US)',
      emel: 'Emel, Female (Turkish, Turkey)',
      ahmet: 'Ahmet, Male (Turkish, Turkey)',
      alara: 'Alara, Female (Turkish, Turkey)',
      baris: 'Baris, Male (Turkish, Turkey)',
      george_gb: 'George, Male (English, British)',
      lily: 'Lily, Female (English, British)',
      noemi: 'Noemi, Female (Hungarian, Hungary)',
      tamas: 'Tamas, Male(Hungarian, Hungary)',
      amalia: 'Amalia, Female (Hungarian, Hungary)',
      monsieur: 'Monsieur, Male (French, FR)',
      darine: 'Darine, Female (French, FR)',
      althea: 'Althea, Female (Filipino, Philippines)',
      bagwis: 'Bagwis, Male (Filipino, Philippines)',
      blessica: 'Blessica, Female (Filipino, Philippines)',
      angelo: 'Angelo, Male (Filipino, Philippines)',
      rosamie: 'Rosamie, Female (Filipino, Philippines)',
      crisanto: 'Crisanto, Male (Filipino, Philippines)',
    },
    save_n_continue: 'Save & Continue',
    save_n_continue_to_next: 'Save & Continue to next block',
    discard_n_continue: 'Discard & Continue',
    unsaved_changes: 'Unsaved Audio Changes',
    unsaved_changes_desc:
      'Audio text changes you have made will be lost. Kindly save and continue.',
    unsaved_notes: 'Unsaved Speaker Notes',
    unsaved_speaker_notes_desc:
      'Speaker notes changes you have made will be lost. Kindly save and continue.',
    show_video_narration: 'Add video overlay'
  },
  highlight: {
    label: 'Spotlight',
    on: 'ON',
    off: 'OFF',
    note:
      'Adjust the spotlight by clicking & dragging the corners. You can drag to move them as well.',
    apply_desc: 'Disable spotlight in all clips?'
  },
  blur: {
    title: 'Blur Sections',
    label: 'Blur',
    editor: 'Blur',
    available_blurs: 'Available Blurs',
    remove: 'Remove',
    modal: {
      heading: 'Blur Image',
      note:
        'Drag and select the area you wish to blur and click on "Blur Selection & Save"',
      add_blur: 'Add Blur',
      discard_selection: 'Discard Selection',
      blur_cta: 'Blur Selection & Save',
      back_to_view: 'Close Blur Editor',
      save: 'Save Blurs'
    },
    empty: 'No blurs added yet.'
  },
  move_frame: {
    save: 'Save',
    clear: 'Reset',
    tile: 'Adjust Visual Narration',
    apply_desc: 'Apply this change to all clips?'
  },
  crop_frame: {
    save: 'Crop Video Frame',
    update: 'Update Frame',
    clear: 'Revert to Original',
    title: 'Crop Video Track',
    description:
      'Select a specific portion of the video frame and remove everything outside of it.',
    apply_desc: 'Apply this change to all clips?'
  },
  layout: {
    title: 'Layout',
    apply_desc: 'Apply this preferences to all clips?'
  },
  audio: {
    title: 'Add Audio',
    tts: {
      cta: 'Generate Audio'
    },
    apply_desc: 'Apply voice settings to all clips?'
  },
  border: {
    type: 'Border Type',
    style: 'Border Style',
    apply_desc: 'Apply border to all clips?'
  },
  background: {
    title: 'Background',
    desc:
      'You can choose a background color of your choice or upload an image as background using file upload.',
    color: 'Color',
    image: 'Image',
    file_desc: 'Drag and drop your image here',
    file_note: 'Formats supported: .jpeg & .png',
    file_action: 'or Open files...',
    apply_desc: 'Apply this preferences to all clips?'
  },
  objects: {
    label: 'Add object',
    textbox_layer: 'Textbox',
    rectangle_layer: 'Rectangle',
    arrow_layer: 'Arrow',
    empty:
      'No shapes or objects added yet. Start by choosing one of the objects above.',
    clone: 'Clone Layer'
  },
  spotlights: {
    label: 'Add Spotlight',
    layer: 'Spotlight',
    empty:
      'Start by adding a spotlight using the button below.',
    copy_time: 'Use Current Player time'
  },
  annotations: {
    label: 'Add Layer',
    layer: 'Layer',
    empty:
      'Start by adding a annotation layer using the button below.',
    copy_time: 'Use Current Player time'
  },
  transition: {
    label: 'Slide Transition'
  },
  block: {
    label: 'Blocks',
    delete: {
      title: 'Are you sure you want to delete this recording?',
      description:
        'On deleting, this recording will be deleted forever. All the images inserted into this recording will not be available anymore.',
      delete_btn: 'Yes, Delete it!',
      cancel: "No, don't delete it"
    }
  },
  upload_recordings: {
    title: 'Upload Recordings',
    media_library: 'Media Library',
    upload: 'Upload your recording',
    recordings: 'Recordings',
    desc:
      'Add your screen recordings that you wanted to edit, later create blocks and add narration',
    exit: 'Back To Video Editor',
    instant_videos: 'Available Instant Videos',
    preserve_audio: 'Preserve Audio Track'
  },
  add_description: 'Add video description',
  duplicate_block: 'Duplicate Block',
  ignore_block: 'Ignore Block',
  revoke_ignore_block: 'Turn on block',
  delete_block: 'Delete Block',
  fill_image: 'Background',
  font_color: 'Font',
  override_intro: 'Override Image',
  upload_intro_image: 'Upload',
  change_intro_type: 'Change Intro',
  intro_image: {
    title: 'Choose your Intro Image',
    custom_image: 'Custom Image',
    custom_video: 'Custom Video',
    system_generated: 'Image auto generated based on your title',
    set_as_intro: 'Set as Intro'
  },
  block_settings: {
    title: 'Block level settings'
  },
  cta: {
    title: 'Call-To-Action',
    description:
      'Add a link to your video, guiding viewers to a next step or a helpful reference',
    button: 'Set Call-To-Action',
    button_success: 'Call-To-Action has been set!',
    label: 'CTA Label',
    link: 'CTA Url'
  },
  chapters: {
    title: 'Chapters',
    description:
      'Allow your audience to easily navigate within the video. Especially useful for longer videos that have informative content.',
    enable_chapter: 'Mark this block as chapter start',
    label: 'Chapter Name',
    button: 'Set Chapter',
    button_success: 'Chapter has been set!',
    add: 'Add Chapters',
    placeholder: 'Easily mark chapters for your video'
  },
  canvas: {
    step: '{current} of {total}',
    intro: 'Introduction',
    outro: 'Thank you note',
    video_narration: 'Video Narration',
    screen_recording: 'Screen Recording',
    uploaded_video: 'Uploaded Video',
    intro_text: 'Intro audio will be played at the begining of the video',
    outro_text:
      'Thank you note to your listeners that can be played at the end of the video',
    screen_recording_text: 'No interactions were made in this recording!'
  },
  onboard: {
    loading: 'Generating blocks from video...',
    translating:
      'Audio conversion/translation in progress. This might take a minimum of few seconds to a maximum of few minutes.',
    auto_publishing:
      'We are publishing your uploaded file. This might take a minimum of few seconds to a maximum of few minutes. We will email you once the video is live.',
    insta_auto_publishing:
      'We are processing your recording. Please wait for a few minutes.',
    importing_blocks:
      'We are importing blocks from another video. This might take a minimum of few seconds to a maximum of few minutes.',
    adding_snippets: 'Adding snippets is in progress.'
  },
  error: {
    blank: 'Please enter a narration',
    processing: {
      description:
        'We regret to inform you that we encountered some errors while processing this video and could not proceed further',
      record: 'Record Again'
    }
  },
  mismatch: {
    text:
      'If you think there is a mismatch with the highlight area and the underlying image.',
    link: 'Click here',
    set: 'Set the selected image'
  },
  insert: {
    title: 'Insert',
    options: {
      search: 'Type to search videos',
      image: 'Insert Image',
      image_desc:
        'Insert images to add more contextual information within videos',
      uploaded_video: 'Insert Video',
      uploaded_video_desc: 'Import video files(.mp4 or .mov) from your device',
      // insert_blocks: 'Import Trainn Video',
      insert_blocks: 'Import Video from Workspace Library',
      insert_blocks_desc:
        'Import blocks from another Walkthrough video in to the current recording',
      insert_video_snippet: 'Insert Video Clips',
      insert_video_snippet_desc:
        'Import video into the media library & create clips for editing',
      slide: 'Insert Slide',
      video: 'Insert Video Narration',
      video_desc: 'Record your own narration with camera and microphone'
    },
    button: 'Insert Block'
  },
  insert_video_snippets: {
    title: 'Insert Video Snippets',
    add_snippets: 'Add Snippets',
    snippet: 'Snippet',
    snippets: 'Snippets',
    start_time: 'Start Time',
    end_time: 'End Time',
    recording: 'Recording',
    split_at: 'Split at',
    copy_time: 'Copy Current Player Time',
    snippet_desc:
      'Divide the important pieces of the recording for which you wish to add narration into snippets by giving the start & end time.',
    // snippet_desc:
    //   'Add snippets that you wanted to include as blocks by giving the start time and the end time. Later add highlights and narration.',
    empty_title: 'No recordings found!',
    empty_desc:
      'Add your desktop and mobile screen recordings that you wanted to be added as blocks, later create blocks and add narration',
    keyboard_controls:
      'Use keyboard shortcuts to play and pause the video, and copy the current player time into the Start and End time inputs using the copy icon.',
    single_clip_info: 'Choose the frame from the video that you wish to extract as image by adjusting the slider to the exact timestamp and then click "Insert as Image"',
  },
  edit_video_snippet: {
    title: 'Edit Video Snippets',
    update: 'Update',
    snippet: 'Snippet',
    snippets: 'Snippets',
    start_time: 'Start Time',
    end_time: 'End Time',
    recording: 'Recording',
    copy_time: 'Copy Current Player Time'
  },
  upload_image: 'Upload image',
  image_settings: 'Image Settings',
  fill_color: 'Fill for your image',
  preview: {
    title: 'Clip preview and audio',
    desc: 'Add or edit voiceovers using Trainn’s various narration modes.',
    cta: 'Preview',
    cta_publish: 'Preview & Publish',
    cta_publish_for_submit: 'Preview & Submit',
    publish: 'Publish',
    publish_for_submit: 'Submit',
    exit: 'Exit Preview',
    edit_video: 'Edit Video',
    pre:
      'Most of the narrations are not provided. Try providing narration for all the blocks for better experience.',
    proceed_anyway: 'Proceed anyway',
    action_title: 'View Clip Timeline',
    action_note:
      'Use the timeline view to trim clips, split, or adjust clip speed.',
    switch_to_timelime: 'Switch to Timeline View'
  },
  settings: {
    title: 'Video Settings'
  },
  voice_settings: {
    title: 'Voice Settings',
    manual_info:
      'By changing from Manual Narration to Text-To-Speech, all your previously recorded narrations will be deleted.',
    tts_info:
      'By changing from Text-To-Speech to Manual narration, all your voiceovers will be deleted and the entered texts will be saved as speaker notes for your reference.',
    description:
      'The translated video will be in draft state. You can modify the video if needed and publish it.'
  },
  tip: 'Quick tip: You can adjust the highlight by clicking & dragging',
  disable: {
    hidden: 'Block has been hidden',
    ignored: 'Block has been ignored',
    options: {
      hide: 'Hide block',
      hide_help: 'Block will be hidden in the final video.',
      ignore: 'Ignore block',
      ignore_help:
        'Block will be shown in the final video, but it will be ignored for analytics, quick access in video player and editing.'
    }
  },
  tool_tip_tts: {
    title: 'Tips to make your voiceover to sound more human',
    tip1: `Use 'Comma' for short pauses. (<b> , </b>)`,
    tip2: `Use a 'Line break' for long pauses. (<b> &#8617; </b>)`,
    tip3: `Enclose keyword or words with asterisk for a slow-paced enunciation. (<b> *word* </b>)`,
    tip4: `Enclose words with double-asterisk for a louder enunciation. (<b> **Flame on!** </b>)`,
    tip5: `Add a pipe symbol anywhere in your script for a brief pause. (<b> | </b>)`,
    footer: 'Try different combinations on your script to nail the right pitch',
    okay: 'Okay'
  },
  advanced: {
    add_tools: 'Add Drawing Tools',
    switch_to_spotlight: 'Switch to Spotlight Mode',
    switch: 'Switch',
    tools: 'Drawing Tools',
    edit: 'Edit',
    save: 'Save',
    disable_highlight: 'You can either add a spotlight or can draw shapes.',
    note:
      'Click on the desired tool and start editing. You can delete it whenever you want.',
    shapes: {
      add: 'Add Shapes',
      rectangle: 'Rectangle',
      arrow: 'Arrow',
      text: 'Text'
    },
    properties: 'Properties',
    stroke_color: 'Stroke Color',
    fill_color: 'Fill Color',
    stroke_width: 'Stroke Width',
    font_size: 'Font Size'
  },
  styles: {
    apply: 'Yes'
  },
  voiceover: {
    title: 'Voiceover',
    save_changes: 'Save changes',
    tts: {
      placeholder: ''
    }
  },
  actions: {
    trim: 'Trim',
    trim_clip: 'Trim Clip',
    undo: 'Undo',
    redo: 'Redo',
    cancel: 'Cancel',
    split: 'Split',
    adjust_frame: 'Adjust Frame',
    add_clip: 'Add new clip',
    select: 'Select',
    merge_clips: 'Merge clips',
    merge: 'Merge',
    reorder_clips: 'Reorder clips',
    reorder: 'Reorder',
    reorder_note: 'Drag to reorder',
    advanced: 'Advanced clip operations',
    delete_clip: 'Delete',
    hide_clip: 'Hide',
    show_clip: 'Unhide',
    clone_clip: 'Clone',
    freeze_clip: 'Lock Clip',
    unFreeze_clip: 'Unlock',
    delete_clips: 'Delete clips',
    delete: 'Delete',
    clear_selection: 'Clear selection',
    merge_and_save: 'Merge',
    merge_and_close: 'Merge and close',
    delete_and_close: 'Delete',
    merge_tooltip: 'Merge adjacent clips only',
    edit_clip: 'Edit Image'
  },
  trim_clip: 'Trimming: Clip %{num}',
  trim_desc:
    'Trim clip to remove unwanted frames at the start or the end — you can always restore a trimmed clip to it’s original version.',
  split_clip: 'Splitting: Clip %{num}',
  split_desc:
    'Split clip at any point in time to create a new clip — you can always merge the clip back.',
  clip_timeline: {
    title: 'Advanced Clip Editing',
    desc:
      'Each clip is composed of different tracks like video/image, annotation, narration and so on. Select the specific track below to adjust attributes like playback speed, duration, and delays.',
    desc_1: 'Use the handles of the track in the timeline to trim the clip.',
    apply_save: 'Update the clip with the changes made.',
    save: 'Save Changes',
    reset: 'Reset',
    reset_to_original: 'Reset to original',
    sec: 'seconds',
    split_label: 'Split Clip',
    split_note:
      ' To split a clip into two move the needle to the desired position and click “split clip”.',
    video_track: {
      label: 'Video track',
      title: 'Adjust playback speed:',
      desc:
        'Make boring actions like filling forms or scrolling to play faster in the video by adjusting this value.',
      clip_speed: 'Playback speed'
    },
    filler_track: {
      label: 'Filler track',
      title: 'Adjust frame duration:',
      desc:
        'Adjust the length of the video by change the duration of the filler track.',
      duration: 'Duration'
    },
    narration_track: {
      label: 'Narration track',
      offset_start: 'Delay',
      title: 'Adjust narration delay:',
      desc:
        'Adjust the narration track start time. Enter the value if there has to be a delay.'
    },
    image_track: {
      label: 'Image Track',
      duration: 'Duration'
    },
    split_confirmation: {
      title: 'Are you sure?',
      desc: 'You are about to split this clip at',
      cancel: 'Cancel',
      action: 'Split'
    },
    delete_confirmation: {
      title: 'Delete Clip?',
      desc: 'The selected clip(s) will be permanently deleted from this video.',
      cancel: 'Cancel',
      action: 'Delete'
    },
    delete_multiple_error: 'You cannot delete all the clips in a track.'
  },
  zoom: {
    title: 'Zoom and transitions',
    description:
      'Set up your zooming animations to ensure the focus is on the right place while explaining a feature.',
    disabled: 'Clip duration should be minimum 2 seconds to enable zoom.',
    enabled: 'Enable zoom effect for this clip?',
    duration: 'Zoom animation can be set up for:',
    transition: 'Enable to make zooming look smooth',
    transition_title: 'Enable zoom transition settings?',
    offset_start: 'Start zoom after:',
    value: 'Zoom Level',
    start_at: 'start at',
    end_at: 'end at',
    start: 'start',
    end: 'end',
    position: 'Zoom Position',
    transition_time: 'Transition Time',
    start_transition_duration: 'Start Transition Duration',
    end_transition_duration: 'End Transition Duration',
    zoom_out: 'Zoom/pan back to initial value/position',
    save_changes: 'Save Changes',
    apply_desc: 'Disable zoom in all video clips?'
  },
  resolution: {
    1080: '1080p',
    720: '720p'
  },
  bgm: {
    title: 'Change Background Score',
    choose_from_library: 'Choose from library',
    disable_bgm: 'Disable background score',
    upload_own_bgm: 'Upload your own background score'
  },
  generate_image: {
    title: 'Generate custom thumbnail',
    save: 'Save and use image',
    choose_template: 'Choose a template',
    upload_image: 'Upload an image',
    customize: 'Customize',
    desc:
      'Each template offers limited but powerful controls to genrerate “wow” images in a jiffy.',
    labels: {
      background: 'Background',
      fill_color: 'Fill Color',
      accent_color: 'Accent Color',
      typography: 'Typography',
      choose_font: 'Choose Font',
      choose_font_size: 'Font Size',
      font_color: 'Font Color',
      text_value: 'Text Content',
      hero_image: 'Hero Image'
    }
  },
  subtitles: {
    title: 'Subtitles and closed captions (CC)',
    description:
      'The texts are system generated. You can modify by clicking on the textbox. Changes may take a few seconds to update after publishing.'
  },
  assist: {
    modal_title: 'Rephrase using AI',
    suggestion: 'Choose from the suggestions below:',
    box_title: 'Your text:',
    button: {
      update: 'Update'
    }
  },
  discard_modal: {
    unsaved_changes: 'Discard changes?',
    unsaved_changes_description: 'You have unsaved changes',
    keep_editing: 'Keep Editing',
    discard: 'Discard'
  }
};

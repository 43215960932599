export default {
  button: {
    logout: 'Log out',
    update: 'Update',
    delete: 'Delete',
    deactivate: 'Deactivate',
    activate: 'Activate',
    clone: 'Clone',
    duplicate: 'Duplicate',
    remove: 'Remove',
    create: 'Create',
    upload: 'Upload',
    upload_video: 'Upload Video',
    upload_document: 'Upload Document',
    create_folder: 'Create New Folder',
    upload_recording: 'Upload Recording',
    manage_recordings: 'Manage Recordings',
    go_to_upload_recordings: 'Go To Upload Recordings',
    create_collection: 'Create Collection',
    collection: 'Collection',
    move_folder: 'Move to...',
    add: 'Add',
    close: 'Close',
    insert: 'Insert',
    install_extension: 'Install Extension',
    create_video: 'Create',
    create_upload_video: 'Create / Upload',
    edit: 'Edit',
    cancel: 'Cancel',
    delete_video: 'Delete Video',
    proceed: 'Proceed',
    manage_workspace: 'Manage your workspace',
    manage_account: 'Manage your account',
    play: 'Play',
    pause: 'Pause',
    play_sample: 'Play Sample',
    stop: 'Stop',
    continue: 'Continue',
    listen: 'Listen',
    convert: 'Convert',
    dismiss: 'Dismiss',
    slideshow: 'Slideshow',
    save_n_continue: 'Save and Continue',
    save_changes: 'Save Changes',
    save_n_close: 'Save and Close',
    save_n_back: 'Save & Return',
    regenerate: 'Regenerate',
    copy: 'Copy',
    copy_link: 'Copy Link',
    upgrade: 'Upgrade',
    switch_plan: 'Switch Plan',
    downgrade: 'Downgrade',
    start_trial: 'Start Free Trial',
    free_14_days: 'free for 14 days',
    subtitles: 'Subtitles',
    replay: 'Replay',
    add_addon: 'Add Addon',
    prev: 'Prev',
    next: 'Next',
    new: 'New',
    new_folder: 'New Folder',
    lets_talk: "Let's talk",
    group: 'Group',
    group_items: 'Group Items',
    join: 'Join',
    publish: 'Publish',
    share: 'Share',
    export: 'Export',
    preview: 'Preview',
    add_videos: 'Add Videos',
    add_articles: 'Add Articles',
    start_creating: 'Start Creating',
    lets_create: "Let's Create",
    move: 'Move',
    move_to: 'Move To',
    move_video_to: 'Move video to',
    clear_selection: 'Clear',
    trash_bin: 'Trash Bin',
    done: 'Done',
    select: 'Select',
    import: 'Import',
    save: 'Save',
    advanced: 'Advanced',
    set: 'Set',
    upload_from_device: 'Upload from Device',
    import_trainn_instant: 'Import Trainn Instant',
    import_from_library: 'Import From Library',
    more: 'More',
    move_to_folder: 'Move to Folder',
    add_to_collection: 'Add to Collection',
    upload_file: 'Upload File',
    read_more: 'Read more',
    new_article: 'New Article',
    edit_article: 'Edit Article',
    delete_article: 'Delete Article',
    article_settings: 'Knowledge Hub Settings',
    guide_settings: 'Settings',
    trainn_library: 'Trainn library',
    create_article: 'Create Article',
    export_logs: 'Export Email Logs ',
    create_guide: 'Create Guide',
    create_manual: 'Create Manual',
    generate_guide: 'Generate Interactive Guide',
    unsupported_upgrade: 'The number of users currently exceeds the limit allowed in this plan. To upgrade, please remove additional users or contact us for assistance.',
    export_video_analytics: 'Export',
    shareable_link_settings: 'Share Settings',
    document_settings: 'Document Settings'
  },
  link: {
    back: 'Back',
    go_back: 'Go Back',
    copy: 'Copy',
    open: 'Open',
    download: 'Download',
    copy_code: 'Copy Code',
    copy_link: 'Copy Shareable Link',
    preview_code: 'Preview Code',
    preview_link: 'Preview Link',
    copied: 'Copied',
    downloading: 'Downloading',
    back_to_app: 'Back to app',
    take_me_to_app: 'Take me to app',
    goto_videos: 'Go to videos',
    goto_create_video: 'Go to create video',
    gif_copy: 'Copy Shareable Gif',
    visit_link: 'YouTube link',
    cancel: 'Cancel',
    view_more: 'View more',
    view: 'View',
    go_to_app: 'Go To App',
    preview: 'Preview',
    analytics: 'Analytics',
    record: 'Record',
    link_copied: 'Link Copied to Clipboard',
  },
  headers: {
    videos: 'Videos',
    collections: 'Collections'
  },
  intercom: {
    redirecting: 'We are connecting to intercom...',
    setup: {
      heading: 'Link your workspace with Intercom',
      description:
        'Fill in the details of your Trainn workspace that you want to link with Intercom.',
      note1: 'Go to Your Profile',
      note2:
        'From your Account Settings, get your Workspace Domain Name & API key'
    },
    label: {
      workspace_name: 'Workspace Domain Name',
      api_key: 'API Key',
      submit: 'Complete Installation'
    },
    connect: {
      cta: 'Connect',
      heading: 'Connect your Trainn workspace with Intercom',
      description:
        'Share Trainn videos easily from your Intercom inbox and offer a flawless support interaction experience.',
      label:
        'Offer self-serve support for customers by sharing How-to Videos from your Intercom Inbox.',
      signup: 'Try Trainn - <i>&nbsp;for free</i>'
    }
  },
  status: {
    active: 'Active',
    draft: 'Draft',
    drafting: 'Drafting',
    live: 'Live',
    publishing: 'Publishing',
    pending: 'Pending',
    trial: 'In Trial',
    cancelled: 'Cancelled',
    failed: 'Failed',
    published: 'Published',
    copy: 'Copy Link',
    copied: 'Link Copied'
  },
  empty: {
    videos: 'Create your first screen recorded training!',
    collections:
      'Learning path lets you share a group of videos to your audiences',
    create_collection: 'Create your first learning path',
    upgrade_to_create_collection:
      'Your current plan does not allow you to create learning paths. ',
    collections_desc:
      'Learning path lets you share a group of videos to your audiences',
    install_extension:
      'Install the browser extension and start creating your first video!'
  },
  text: {
    new: 'New Collection',
    existing: 'Existing Collection',
    created: 'Created',
    count: 'Count',
    attempted: 'Falied attempt',
    published: 'Published',
    selected: 'selected',
    items: 'items',
    coming_soon: 'Coming Soon',
    workspace: 'Workspace',
    untitled_video: 'untitled-video',
    untitled_guide: 'untitled-guide',
    switch_workspace: 'Switch Workspace',
    contact_support: 'Contact Support',
    contact_us_mail: `<a href="mailto:support@trainn.co"> Contact us </a>`,
    kb: 'Knowledge Center',
    recording: 'Recording',
    group: 'Group',
    presentation: 'Presentation',
    screen_recording: 'Walkthrough',
    manual_upload: 'Uploaded',
    walkthrough: 'Walkthrough',
    insta: 'Instant',
    hub: 'Hub',
    video: 'Video',
    videos: 'Videos',
    article: 'Article',
    articles: 'Articles',
    guide: 'Guide',
    guides: 'Guides',
    document: 'Document',
    documents: 'Documents',
    show_interactive_mode: 'Show Interactive Mode',
    show_guide_mode: 'Show Guide Mode',
    interactive_mode: 'Interactive mode',
    guide_mode: 'Guide mode',
    library: 'Library',
    team_library: 'Team Library',
    private_library: 'Private Library',
    all_content_description: 'Manage, record, edit, and publish videos.',
    all_videos_description:
      'Manage all your videos here. You can record, edit and publish videos to the Library. ',
    all_articles_description:
      'Manage all your articls here. You can create, edit and publish articles to the Library.',
    all_collections: 'All Collections',
    all_collections_desc: 'Organize multiple videos, guides and articles into a collection.',
    all_learning_paths: 'All Learning Paths',
    instant: 'Instant',
    insta_contact_us: `Trainn Instant is available only in the Enterprise plan. Kindly <a href="mailto:support@trainn.co"> contact sales </a> to know more about it.`,
    upload_contact_us: `Upload video is available only in the Enterprise plan. Kindly <a href="mailto:support@trainn.co"> contact us </a> to know more about it.`,
    live_video_collection_restriction:
      'Only published videos can be added to learning path. Kindly publish your videos.',
    contact_us: `Kindly <a href="mailto:support@trainn.co"> contact us </a> to enable it.`,
    reached_limit: "You've reached your maximum video limit.",
    denied_permission: 'You do not have permission to perform this action',
    current_plan_limitations:
      'Your current plan does not allow you to perform this action',
    current_plan: 'Current Plan',
    in_trial: 'In Free Trial',
    free_trial: '1 month free trial',
    cancelled: 'Cancelled',
    or: 'or',
    created_on: 'Created on',
    course_completion: 'Number of completions',
    off: 'Off',
    updated: 'Updated!',
    month: 'month',
    in_trial_usage:
      'You are currently in Trial period. You have unlimited enagement minutes.',
    plan_usage:
      'Your videos have consumed %{current_count} engagement minutes in the current billing period.',
    plan_usage_exceeded: `Your videos have consumed %{current_count} engagement minutes in the current billing period. <br/> You will be charged $%{overage_charges} for the exceeded %{exceeded_count} minutes.`,
    learn_more: 'Learn More',
    complete: 'complete',
    completed: 'Completed',
    locked: 'Access Restricted',
    more: 'more',
    search_term: 'Search Term',
    redirect_to_google: 'Please wait while we redirect to Google...',
    redirecting: 'Please wait while we redirect...',
    redirect_back_to_trainn:
      'Please wait we are redirecting you back to the application...',
    current: 'Current',
    old: 'Old',
    private: 'Private',
    included_videos: 'Included Videos',
    tag_line: 'Accelerate product adoption and user onboarding via videos',
    share_marketing: 'Create product videos in a snap.',
    launch_cta: 'Start creating a video',
    made_with: 'Made with',
    trainn_co: 'Trainn.co',
    watch_video: 'Watch Video',
    see_it_in_action: 'Watch video to see it in action.',
    getting_ready: 'We are getting everything ready...',
    default_list: 'Default List',
    loading: 'Loading...',
    saving: 'Saving...',
    channel: 'Channel',
    loading_title: 'Just a moment,',
    loading_desc: 'while we get things ready.',
    switch_to_desktop:
      'This content cannot be access via mobile. Please switch to Desktop view.',
    close_menu: 'Close Menu',
    install_extension: 'Install Trainn for chrome',
    knowledge_center: 'Knowledge center',
    view_samples: 'View samples',
    fetaure_request: 'Feature request',
    chat: 'Send us a message',
    trash: 'Trash bin',
    trash_description: 'Items in the bin are deleted forever after 7 days.',
    trash_empty: 'There’s nothing here yet! ',
    content_empty: 'This folder seems empty.',
    delete_folder_tooltip: 'Delete folder',
    trash_empty_description:
      'Folders or Videos that are deleted will appear here.',
    empty_folder_description:
      'Move a video into this folder to make it easy to find and share.',
    delete_folder: 'Are you sure you want to delete this folder?',
    delete_folder_confirm: `Please enter <b>"%{name}"</b> to confirm`,
    no_videos_found: 'No videos found.',
    add: 'Add',
    add_videos: 'video(s) to collection...',
    add_articles: 'articles(s) to collection...',
    add_guides: 'guides(s) to collection...',
    add_items: 'item(s) to collection...',
    insert_link: 'Insert Link',
    insert_youtube_video: 'Insert YouTube Video',
    youtube_video_desc: 'Copy the YouTube video URL and paste it below to insert inside this article',
    new_button: 'New Button',
    insert_table: 'Insert Table',
    edit_link: 'Edit Link',
    edit_button: 'Edit Button',
    link: 'Link',
    deleted: 'Deleted',
    editing: 'editing',
    show_timeline: 'Show clip timeline',
    hide_timeline: 'Hide clip timeline',
    can_download_document: 'Allow users to download this document',
    sizes: {
      small: 'Small',
      normal: 'Normal',
      medium: 'Medium',
      large: 'Large',
      huge: 'Huge'
    },
    page: 'Page',
    included_in: 'Included in these Collections:',
    included_in_course: 'Included in these Courses:',
    advanced_settings: 'Advanced Settings',
    short_description: 'Short Description',
    no_transcripts_found: 'No transcripts found',
    enabled: 'Enabled',
    disabled: 'Disabled',
    zoom: 'Zoom',
  },
  errors: {
    select_value: 'Please select a value',
    preview_failed: 'There was an error while loading the PDF. Please try refreshing the page.',
  },
  tiptap: {
    h1: 'Heading 1',
    h2: 'Heading 2',
    h3: 'Heading 3',
    h4: 'Heading 4',
    h5: 'Heading 5',
    h6: 'Heading 6',
    bold: 'Bold',
    italic: 'Italic',
    strikethrough: 'Strikethrough',
    underline: 'Underline',
    link: 'Insert link',
    button: 'Insert Button',
    table: 'Insert Table',
    image: 'Insert Image',
    fullScreen: 'Enter fullscreen',
    fontcolor: 'Text color',
    unorderedlist: 'Bullet list',
    orderedlist: 'Ordered list',
    blockquote: 'Blockquote',
    title_input: 'Title',
    link_input: 'Link',
    code: 'Code',
    Undo: 'Undo',
    Redo: 'Redo',
    align: 'Align',
    leftAlign: 'Left align(Ctrl+Shift+L)',
    centerAlign: 'Center(Ctrl+Shift+E)',
    rightAlign: 'Right align(Ctrl+Shift+R)',
    justify: 'Justify(Ctrl+Shift+J)',
    horizontalLine: 'Horizontal line',
    drop_image_here: 'Paste or Drop your image here or',
    pick: 'Pick a Different Image',
    confirm: 'Upload Image',
    uploading_image: 'Uploading... Hang tight!',
    import_trainn_video: 'Import Video from Library',
    import_trainn_guide: 'Import Guide from Library',
    insert_youtube_video: 'Insert YouTube Video',
    tableSettings: {
      addColumnLeft: 'Insert Column Left',
      addColumnRight: 'Insert Column Right',
      addRowAbove: 'Insert Row Above',
      addRowBelow: 'Insert Row Below',
      deleteColumn: 'Delete Column',
      deleteRow: 'Delete Row',
      mergeCells: 'Merge Cells',
      splitCell: 'Split Cell',
      deleteTable: 'Delete Table',
      toggleHeaderRow: 'Toggle Header Row',
      toggleHeaderColumn: 'Toggle Header Column'
    }
  },
  article: {
    untitled: 'Untitled article',
    empty: 'No articles found',
    table: {
      type: 'Type',
      name: 'Name',
      owner: 'Owner',
      status: 'Status',
      recent_activity: 'Recent activity',
      date_modified: 'Date Modified',
      trashed_on: 'Trashed on',
    },
    delete: {
      title: 'Are you sure you want to delete this article?',
      description: 'On deleting, this article will be deleted forever.'
    },
  },
  guide: {
    untitled: 'Untitled guide',
    empty: 'No guides found',
  },
  document: {
    delete: {
      title: 'Are you sure you want to delete this document?',
      description: 'On deleting, this document will be deleted forever.'
    },
    empty: 'No documents found',
  },
  collection: {
    section: {
      edit_section_name: 'Edit Section Name',
      reorder_section: 'Reorder Section',
      delete_section: 'Delete Section',
      reorder: 'Reorder you sections',
      reorder_desc:
        'Reorder your sections the way you want them to appear in the end user interface.',
      move: 'Move the selected content to another section',
      move_desc: 'The content will be moved to the selected section.',
      create_section: "Create Section",
    },
    add_section: 'Add Section',
    add_video: 'Add Video',
    add_article: 'Add Article',
    add_guide: 'Add Guide',
    add_document: 'Add Document',
    create_section: 'Create Section',
    add: 'Add',
    delete_section_title: 'Are you sure you want to delete this section ?',
    delete_section_desc: 'On deleting, any video/article associated with this section will be removed from the collection.',
    reorder_successful: 'Reorder successful',
  },
  update_tts_with_csv: {
    title: 'Bulk TTS Update',
    errors: {
      no_file: 'Please select a csv file',
      invalid_file: 'Please select a valid csv file'
    }
  },
  clips:{
    selectedCount: `You have selected %{selectedClips} of %{totalClips}`,
    importClips: 'Import Clip(s)',
    importVideo: 'Import Video',
    invalidInput: 'Use a valid input format',
    instruction: 'Use comma or hyphens to denote range of clips',
    from: 'Import Clips from',
    selectAll: 'Select all clips',
    selectRange: 'Select a range of clips',
    placeholder: '1-4, 6, 8'
  }
};
